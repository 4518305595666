export const SET_CHANNELS = 'SET_CHANNELS'

export interface ChannelsState {
	workspaceChannel: any | null
}

interface SetChannels {
	type: typeof SET_CHANNELS,
	channelState: ChannelsState
}

export type ChannelActionTypes = (
	SetChannels
)