import { IPositionable } from "../types";
import moment from "./Moment";
export default class Utils {
  static groupBy<T>(array: T[], key: string): Object {
    return array.reduce((rv, x) => {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }

  static uniqueId(): string {
    return (
      "id-" +
      Math.random()
        .toString(36)
        .substr(2, 16)
    );
  }

  static encodeQueryData(data, allowNullValues = false) {
    let ret = [];
    for (let d in data) {
      if (allowNullValues || (data[d] !== null && data[d] !== undefined && data[d] !== "")) {
        ret.push(encodeURIComponent(d) + "=" + encodeURIComponent(data[d]));
      }
    }
    return ret.join("&");
  }

  static uuid() {
    var uuid = "",
      i,
      random;
    for (i = 0; i < 32; i++) {
      random = (Math.random() * 16) | 0;

      if (i == 8 || i == 12 || i == 16 || i == 20) {
        uuid += "-";
      }
      uuid += (i == 12 ? 4 : i == 16 ? (random & 3) | 8 : random).toString(16);
    }
    return uuid;
  }

  static replaceStringVariables(inputString: string, variables: object) {
    let outputString = inputString;

    for (const key in variables) {
      const value = variables[key] || `{{${key}}}`;
      outputString = outputString.replace(new RegExp(`{{${key}}}`, "g"), value);
    }

    return outputString;
  }

  static debounce(func, wait, immediate?: boolean) {
    var timeout;
    return function () {
      var context = this,
        args = arguments;
      var later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }

  getQuarter(date: Date) {
    date = date || new Date();
    var m = Math.floor(date.getMonth() / 3) + 2;
    m -= m > 4 ? 4 : 0;
    var y = date.getFullYear() + (m == 1 ? 1 : 0);
    return [y, m];
  }

  static getCurrentQuarter(): number {
    return moment().quarter();
  }

  static getInitials(value: string = '') {
    return (
      (value || '')
        // Remove numbers
        .replace(/[0-9]/g, "")
        .split(" ")
        .map((n) => n[0])
        .slice(0, 2)
        .join("")
    );
  }

  static stripHtml(html: string) {
    var doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  }

  static getColorFromString(value: string): string {
    let hash = 0;
    for (let i = 0; i < value.length; i++) {
      hash = value.charCodeAt(i) + ((hash << 5) - hash);
      hash = hash & hash;
    }
    var color = "#";
    for (let i = 0; i < 3; i++) {
      let valueHex = (hash >> (i * 8)) & 255;
      color += ("00" + valueHex.toString(16)).substr(-2);
    }

    return color;
  }

  static chunk<T>(array: T[], size: number) {
    var chunks = [],
      i = 0,
      n = array.length;

    while (i < n) {
      chunks.push(array.slice(i, (i += size)));
    }

    return chunks;
  }

  static bytesToSize(bytes: number, decimals = 2) {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  static fileSaverSupported() {
    return !!new Blob;
  }

  static isValidEmail(email: string) {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(email).toLowerCase());
  }

  static isValidTime(time: string) {
    const valueParts = time.split(':').filter(v => v !== '')
    return valueParts.length === 2 && valueParts[0].length == 2 && valueParts[1].length === 2
  }

  static sortByPosition<T extends IPositionable>(items: T[]) {
    return items.sort((i1, i2) => i1.position - i2.position)
  }
}
