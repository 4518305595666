import {
  AccountantCurrentUser,
  CurrentUser,
  Settings,
  Subscription,
  Workspace,
} from "../../types";

export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const UNSET_CURRENT_USER = "UNSET_CURRENT_USER";
export const SET_TOKEN = "SET_TOKEN";
export const UNSET_TOKEN = "UNSET_TOKEN";
export const UPDATE_WORKSPACE = "UPDATE_WORKSPACE";
export const UPDATE_SETTINGS = "UPDATE_SETTINGS";
export const UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION";

export interface AuthenticationState {
  currentUser: CurrentUser | null;
  accessToken: string | null;
  currentAccountant: AccountantCurrentUser | null;
}

interface SetCurrentUserAction {
  type: typeof SET_CURRENT_USER;
  currentUser: CurrentUser;
}

interface UnsetCurrentUserAction {
  type: typeof UNSET_CURRENT_USER;
}

interface SetAccessTokenAction {
  type: typeof SET_TOKEN;
  accessToken: string;
}

interface UnsetTokenAction {
  type: typeof UNSET_TOKEN;
}

interface UpdateWorkspaceAction {
  type: typeof UPDATE_WORKSPACE;
  workspace: Workspace;
}

interface UpdateSettingsActions {
  type: typeof UPDATE_SETTINGS;
  settings: Settings;
}

interface UpdateSubscriptionAction {
  type: typeof UPDATE_SUBSCRIPTION;
  subscription: Subscription;
}

export type AuthenticationActionTypes =
  | SetCurrentUserAction
  | UnsetCurrentUserAction
  | SetAccessTokenAction
  | UnsetTokenAction
  | UpdateWorkspaceAction
  | UpdateSettingsActions
  | UpdateSubscriptionAction;
