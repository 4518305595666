import { CustomFieldType } from "../../types";
import {
  SHOW_CONTACT_MODAL,
  CLOSE_CONTACT_MODAL,
  SHOW_PROJECT_MODAL,
  CLOSE_PROJECT_MODAL,
  ModalsState,
  ModalsActionTypes,
  SHOW_EXPENSE_MODAL,
  CLOSE_EXPENSE_MODAL,
  SHOW_TIME_ENTRY_EXPORT_MODAL,
  CLOSE_TIME_ENTRY_EXPORT_MODAL,
  SHOW_TIME_ENTRY_MODAL,
  CLOSE_TIME_ENTRY_MODAL,
  SHOW_SEND_LEDGER_ITEM_MODAL,
  CLOSE_SEND_LEDGER_ITEM_MODAL,
  SHOW_SETTINGS_EMAIL_TEMPLATE_MODAL,
  CLOSE_SETTINGS_EMAIL_TEMPLATE_MODAL,
  SHOW_PAYMENT_DETAILS_MODAL,
  CLOSE_PAYMENT_DETAILS_MODAL,
  SHOW_IMPORT_PRODUCTS_MODAL,
  CLOSE_IMPORT_PRODUCTS_MODAL,
  SHOW_IMPORT_TIME_ENTRIES_MODAL,
  CLOSE_IMPORT_TIME_ENTRIES_MODAL,
  SHOW_IMPORT_EXPENSES_MODAL,
  CLOSE_IMPORT_EXPENSES_MODAL,
  SHOW_TAXES_MODAL,
  CLOSE_TAXES_MODAL,
  SHOW_CONFIRM_MODAL,
  CLOSE_CONFIRM_MODAL,
  SHOW_PRODUCT_MODAL,
  CLOSE_PRODUCT_MODAL,
  SHOW_MOBILE_APP_MODAL,
  CLOSE_MOBILE_APP_MODAL,
  SHOW_FILE_MODAL,
  CLOSE_FILE_MODAL,
  SHOW_MOVE_FILES_MODAL,
  CLOSE_MOVE_FILES_MODAL,
  SHOW_EXPORT_LEDGER_ITEMS_MODAL,
  CLOSE_EXPORT_LEDGER_ITEMS_MODAL,
  SHOW_EXPORT_EXPENSES_MODAL,
  CLOSE_EXPORT_EXPENSES_MODAL,
  SHOW_EXPENSE_CATEGORY_MODAL,
  CLOSE_EXPENSE_CATEGORY_MODAL,
  SHOW_PROJECT_STATUS_UPDATE_MODAL,
  CLOSE_PROJECT_STATUS_UPDATE_MODAL,
  SHOW_EXPORT_PRODUCTS_MODAL,
  CLOSE_EXPORT_PRODUCTS_MODAL,
  SHOW_CUSTOM_FIELD_MODAL,
  CLOSE_CUSTOM_FIELD_MODAL,
  SHOW_BOARD_MODAL,
  CLOSE_BOARD_MODAL,
  SHOW_TASK_MODAL,
  CLOSE_TASK_MODAL,
  SHOW_CALENDAR_EVENT_MODAL,
  CLOSE_CALENDAR_EVENT_MODAL,
  SHOW_ATTACHMENTS_VIEWER_MODAL,
  CLOSE_ATTACHMENTS_VIEWER_MODAL,
  SHOW_ADD_ACCOUNTANT_MODAL,
  CLOSE_ADD_ACCOUNTANT_MODAL,
  SHOW_USER_WORKSPACE_SETTING_MODAL,
  CLOSE_USER_WORKSPACE_SETTING_MODAL,
  SHOW_BULK_TIME_ENTRY_MODAL,
  CLOSE_BULK_TIME_ENTRY_MODAL,
  SHOW_CALCULATE_ADVANCE_MODAL,
  CLOSE_CALCULATE_ADVANCE_MODAL,
  SHOW_SEND_CONTRACT_MODAL,
  CLOSE_SEND_CONTRACT_MODAL,
  SHOW_SHARE_LINKS_MODAL,
  CLOSE_SHARE_LINKS_MODAL,
  SHOW_SEND_PROPOSAL_MODAL,
  CLOSE_SEND_PROPOSAL_MODAL,
  SHOW_ITEMS_BLOCK_SETTING_MODAL,
  CLOSE_ITEMS_BLOCK_SETTING_MODAL,
  SHOW_ITEM_BLOCK_SETTING_MODAL,
  CLOSE_ITEM_BLOCK_SETTING_MODAL,
  SHOW_DIRECTIONS_MODAL,
  CLOSE_DIRECTIONS_MODAL,
  SHOW_LEDGER_ITEM_PAYMENT_MODAL,
  CLOSE_LEDGER_ITEM_PAYMENT_MODAL,
  SHOW_LEDGER_ITEM_TRANSACTION_HISTORY_MODAL,
  CLOSE_LEDGER_ITEM_TRANSACTION_HISTORY_MODAL,
  SHOW_LEDGER_ITEM_CLAIM_MODAL,
  CLOSE_LEDGER_ITEM_CLAIM_MODAL,
  SHOW_PAYMENT_MODAL,
  CLOSE_PAYMENT_MODAL,
  SHOW_PAYMENT_INITIATION_MODAL,
  CLOSE_PAYMENT_INITIATION_MODAL,
  SHOW_DEAL_MODAL,
  CLOSE_DEAL_MODAL,
  SHOW_DEAL_STAGE_MODAL,
  CLOSE_DEAL_STAGE_MODAL,
  SHOW_CONTENT_BLOCK_TEMPLATE_MODAL,
  CLOSE_CONTENT_BLOCK_TEMPLATE_MODAL,
  SHOW_TAX_MODAL,
  CLOSE_TAX_MODAL,
  SHOW_WORK_TYPE_MODAL,
  CLOSE_WORK_TYPE_MODAL,
  SHOW_DISCOUNT_MODAL,
  CLOSE_DISCOUNT_MODAL,
  SHOW_PEPPOL_PARTICIPANT_MODAL,
  CLOSE_PEPPOL_PARTICIPANT_MODAL,
  SHOW_FINANCIAL_YEAR_MODAL,
  CLOSE_FINANCIAL_YEAR_MODAL,
  SHOW_TRANSACTION_MODAL,
  CLOSE_TRANSACTION_MODAL,
  SHOW_QUESTION_AND_ANSWER_BLOCK_SETTING_MODAL,
  CLOSE_QUESTION_AND_ANSWER_BLOCK_SETTING_MODAL,
  SHOW_PLAYBOOK_SUBMISSION_MODAL,
  CLOSE_PLAYBOOK_SUBMISSION_MODAL,
  SHOW_BULK_PROJECT_STATUS_UPDATE_MODAL,
  CLOSE_BULK_PROJECT_STATUS_UPDATE_MODAL,
  SHOW_SIGNATURE_MODAL,
  CLOSE_SIGNATURE_MODAL,
  SHOW_EMAIL_VIEWER_MODAL,
  CLOSE_EMAIL_VIEWER_MODAL,
  SHOW_CONTACT_GROUP_MODAL,
  CLOSE_CONTACT_GROUP_MODAL,
  SHOW_BULK_CONTACT_GROUP_MODAL,
  CLOSE_BULK_CONTACT_GROUP_MODAL,
  SHOW_EXPORT_CONTACTS_MODAL,
  CLOSE_EXPORT_CONTACTS_MODAL,
  SHOW_CONTACT_TYPE_MODAL,
  CLOSE_CONTACT_TYPE_MODAL,
  SHOW_FILE_UPLOAD_MODAL,
  CLOSE_FILE_UPLOAD_MODAL,
  SHOW_PAYMENT_QR_MODAL,
  CLOSE_PAYMENT_QR_MODAL,
  SHOW_CUSTOM_COMPONENT_MODAL,
  CLOSE_CUSTOM_COMPONENT_MODAL,
  SHOW_DOCUMENT_MODAL,
  CLOSE_DOCUMENT_MODAL,
  SHOW_DOCUMENT_TAG_MODAL,
  CLOSE_DOCUMENT_TAG_MODAL,
  SHOW_EXPORT_DOCUMENTS_MODAL,
  CLOSE_EXPORT_DOCUMENTS_MODAL,
  SHOW_UPLOAD_DOCUMENTS_MODAL,
  CLOSE_UPLOAD_DOCUMENTS_MODAL,
  SHOW_PRODUCT_IMPORT_MODAL,
  CLOSE_PRODUCT_IMPORT_MODAL,
  SHOW_CALL_MODAL,
  CLOSE_CALL_MODAL,
  SHOW_SEND_EMAIL_MODAL,
  CLOSE_SEND_EMAIL_MODAL,
  SHOW_EMAIL_TEMPLATE_MODAL,
  CLOSE_EMAIL_TEMPLATE_MODAL,
  SHOW_SEQUENCE_STEP_MODAL,
  CLOSE_SEQUENCE_STEP_MODAL,
  SHOW_CONTACT_ENROLLMENT_MODAL,
  CLOSE_CONTACT_ENROLLMENT_MODAL,
  SHOW_TWO_FACTOR_AUTHENTICATION_MODAL,
  CLOSE_TWO_FACTOR_AUTHENTICATION_MODAL,
} from "./types";

const initialState: ModalsState = {
  contactModal: {
    show: false,
  },
  projectModal: {
    show: false,
  },
  projectStatusUpdateModal: {
    show: false,
  },
  expenseModal: {
    show: false,
  },
  timeEntryExportModal: {
    show: false,
  },
  timeEntryModal: {
    show: false,
  },
  sendLedgerItemModal: {
    show: false,
    id: "",
    reminder: false,
  },
  settingsEmailTemplateModal: {
    show: false,
  },
  paymentDetailsModal: {
    show: false,
  },
  importProductsModal: {
    show: false,
  },
  importTimeEntriesModal: {
    show: false,
  },
  importExpensesModal: {
    show: false,
  },
  taxesModal: {
    show: false,
  },
  confirmModal: {
    show: false,
    title: "",
    description: "",
    action: { label: " " },
    onConfirm: () => {},
    onCancel: () => {},
  },
  productModal: {
    show: false,
  },
  mobileAppModal: {
    show: false,
  },
  fileModal: {
    show: false,
  },
  moveFilesModal: {
    show: false,
    fileIds: [],
  },
  exportLedgerItemsModal: {
    show: false,
  },
  exportExpensesModal: {
    show: false,
  },
  expenseCategoryModal: {
    show: false,
  },
  exportProductsModal: {
    show: false,
  },
  customFieldModal: {
    show: false,
  },
  boardModal: {
    show: false,
  },
  taskModal: {
    show: false,
  },
  calendarEventModal: {
    show: false,
  },
  attachmentsViewerModal: {
    show: false,
  },
  addAccountantModal: {
    show: false,
  },
  userWorkspaceSettingModal: {
    show: false,
  },
  bulkTimeEntryModal: {
    show: false,
  },
  calculateAmountModal: {
    show: false,
    amount: 0,
  },
  sendContractModal: {
    show: false,
  },
  shareLinksModal: {
    show: false,
  },
  sendProposalModal: {
    show: false,
  },
  itemsBlockSettingModal: {
    show: false,
  },
  itemBlockSettingModal: {
    show: false,
  },
  contentBlockTemplateModal: {
    show: false,
    contentBlockId: null,
  },
  directionsModal: {
    show: false,
  },
  ledgerItemPaymentModal: {
    show: false,
  },
  ledgerItemTransactionHistoryModal: {
    show: false,
  },
  ledgerItemClaimModal: {
    show: false,
  },
  paymentModal: {
    show: false,
  },
  paymentInitiationModal: {
    show: false,
  },
  dealModal: {
    show: false,
  },
  dealStageModal: {
    show: false,
  },
  taxModal: {
    show: false,
  },
  workTypeModal: {
    show: false,
  },
  discountModal: {
    show: false,
  },
  peppolParticipantModal: {
    show: false,
  },
  financialYearModal: {
    show: false,
  },
  transactionModal: {
    show: false,
  },
  questionAndAnswerBlockSettingModal: {
    show: false,
  },
  playbookSubmissionModal: {
    show: false,
  },
  bulkProjectStatusUpdateModal: {
    show: false,
  },
  signatureModal: {
    show: false,
  },
  emailViewerModal: {
    show: false,
  },
  contactGroupModal: {
    show: false,
  },
  bulkContactGroupModal: {
    show: false,
  },
  exportContactsModal: {
    show: false,
  },
  contactTypeModal: {
    show: false,
  },
  fileUploadModal: {
    show: false,
  },
  paymentQRModal: {
    show: false,
  },
  customComponentModal: {
    show: false,
  },
  documentModal: {
    show: false,
  },
  documentTagModal: {
    show: false,
  },
  exportDocumentsModal: {
    show: false,
  },
  uploadDocumentsModal: {
    show: false,
  },
  productImportModal: {
    show: false,
  },
  callModal: {
    show: false,
  },
  sendEmailModal: {
    show: false,
  },
  emailTemplateModal: {
    show: false,
  },
  sequenceStepModal: {
    show: false,
  },
  contactEnrollmentModal: {
    show: false,
  },
  twoFactorAuthenticationModal: {
    show: false,
  },
};

export default (
  state = initialState,
  action: ModalsActionTypes
): ModalsState => {
  switch (action.type) {
    case SHOW_CONTACT_MODAL:
      return {
        ...state,
        contactModal: {
          show: true,
          contact: action.contact || {},
          activeTab: action.activeTab,
          onSubmit: action.onSubmit,
        },
      };
    case CLOSE_CONTACT_MODAL:
      return {
        ...state,
        contactModal: {
          show: false,
          contact: null,
          activeTab: null,
          onSubmit: null,
        },
      };
    case SHOW_PROJECT_MODAL:
      return {
        ...state,
        projectModal: {
          show: true,
          project: action.project || {},
          contactDisabled: action.contactDisabled,
          statusDisabled: action.statusDisabled,
          activeTab: action.activeTab,
          onSubmit: action.onSubmit,
        },
      };
    case CLOSE_PROJECT_MODAL:
      return {
        ...state,
        projectModal: {
          show: false,
          project: {},
          contactDisabled: false,
          statusDisabled: false,
          activeTab: null,
          onSubmit: null,
        },
      };
    case SHOW_PROJECT_STATUS_UPDATE_MODAL:
      return {
        ...state,
        projectStatusUpdateModal: {
          show: true,
          project: action.project || {},
          onSubmit: action.onSubmit,
        },
      };
    case CLOSE_PROJECT_STATUS_UPDATE_MODAL:
      return {
        ...state,
        projectStatusUpdateModal: {
          show: false,
          project: {},
          onSubmit: null,
        },
      };
    case SHOW_EXPENSE_MODAL:
      return {
        ...state,
        expenseModal: {
          show: true,
          expense: action.expense || {},
          supplierDisabled: action.supplierDisabled,
          contactDisabled: action.contactDisabled,
          projectDisabled: action.projectDisabled,
          onSubmit: action.onSubmit,
        },
      };
    case CLOSE_EXPENSE_MODAL:
      return {
        ...state,
        expenseModal: {
          show: false,
          expense: {},
          supplierDisabled: false,
          contactDisabled: false,
          projectDisabled: false,
          onSubmit: null,
        },
      };
    case SHOW_TIME_ENTRY_EXPORT_MODAL:
      return {
        ...state,
        timeEntryExportModal: {
          show: true,
          contactId: action.contactId,
          contactDisabled: action.contactDisabled,
          projectId: action.projectId,
          projectDisabled: action.projectDisabled,
          start: action.start,
          end: action.end,
          type: action.exportType,
          onSubmit: action.onSubmit,
        },
      };
    case CLOSE_TIME_ENTRY_EXPORT_MODAL:
      return {
        ...state,
        timeEntryExportModal: {
          show: false,
          contactId: null,
          contactDisabled: false,
          projectId: null,
          projectDisabled: false,
          start: null,
          end: null,
          type: null,
          onSubmit: null,
        },
      };
    case SHOW_TIME_ENTRY_MODAL:
      return {
        ...state,
        timeEntryModal: {
          show: true,
          timeEntry: action.timeEntry,
          contactDisabled: action.contactDisabled,
          projectDisabled: action.projectDisabled,
          onSubmit: action.onSubmit,
        },
      };
    case CLOSE_TIME_ENTRY_MODAL:
      return {
        ...state,
        timeEntryModal: {
          show: false,
          timeEntry: null,
          contactDisabled: null,
          projectDisabled: null,
          onSubmit: null,
        },
      };
    case SHOW_BULK_TIME_ENTRY_MODAL:
      return {
        ...state,
        bulkTimeEntryModal: {
          show: true,
          contactId: action.contactId,
          contactDisabled: action.contactDisabled,
          projectId: action.projectId,
          projectDisabled: action.projectDisabled,
          onSubmit: action.onSubmit,
        },
      };
    case CLOSE_BULK_TIME_ENTRY_MODAL:
      return {
        ...state,
        bulkTimeEntryModal: {
          show: false,
          contactId: null,
          contactDisabled: null,
          projectId: null,
          projectDisabled: null,
          onSubmit: null,
        },
      };
    case CLOSE_TIME_ENTRY_MODAL:
      return {
        ...state,
        timeEntryModal: {
          show: false,
          timeEntry: null,
          contactDisabled: null,
          projectDisabled: null,
          onSubmit: null,
        },
      };
    case SHOW_IMPORT_EXPENSES_MODAL:
      return {
        ...state,
        importExpensesModal: {
          show: true,
          contactId: action.contactId,
          projectId: action.projectId,
          onSubmit: action.onSubmit,
        },
      };
    case CLOSE_IMPORT_EXPENSES_MODAL:
      return {
        ...state,
        importExpensesModal: {
          show: false,
          contactId: null,
          projectId: null,
          onSubmit: null,
        },
      };
    case SHOW_SEND_LEDGER_ITEM_MODAL:
      return {
        ...state,
        sendLedgerItemModal: {
          show: true,
          id: action.ledgerItemId,
          reminder: Boolean(action.reminder),
          onSubmit: action.onSubmit,
        },
      };
    case CLOSE_SEND_LEDGER_ITEM_MODAL:
      return {
        ...state,
        sendLedgerItemModal: {
          show: false,
          id: null,
          reminder: false,
          onSubmit: null,
        },
      };
    case SHOW_SETTINGS_EMAIL_TEMPLATE_MODAL:
      return {
        ...state,
        settingsEmailTemplateModal: {
          show: true,
          template: action.template,
        },
      };
    case CLOSE_SETTINGS_EMAIL_TEMPLATE_MODAL:
      return {
        ...state,
        settingsEmailTemplateModal: {
          show: false,
          template: null,
        },
      };
    case SHOW_PAYMENT_DETAILS_MODAL:
      return {
        ...state,
        paymentDetailsModal: {
          show: true,
          onSubmit: action.onSubmit,
        },
      };
    case CLOSE_PAYMENT_DETAILS_MODAL:
      return {
        ...state,
        paymentDetailsModal: {
          show: false,
          onSubmit: null,
        },
      };
    case SHOW_IMPORT_PRODUCTS_MODAL:
      return {
        ...state,
        importProductsModal: {
          show: true,
          onSubmit: action.onSubmit,
        },
      };
    case CLOSE_IMPORT_PRODUCTS_MODAL:
      return {
        ...state,
        importProductsModal: {
          show: false,
          onSubmit: null,
        },
      };
    case SHOW_IMPORT_TIME_ENTRIES_MODAL:
      return {
        ...state,
        importTimeEntriesModal: {
          show: true,
          onSubmit: action.onSubmit,
          contactId: action.contactId,
          projectId: action.projectId,
        },
      };
    case CLOSE_IMPORT_TIME_ENTRIES_MODAL:
      return {
        ...state,
        importTimeEntriesModal: {
          show: false,
          onSubmit: null,
          contactId: null,
          projectId: null,
        },
      };
    case SHOW_TAXES_MODAL:
      return {
        ...state,
        taxesModal: {
          show: true,
          onSubmit: action.onSubmit,
        },
      };
    case CLOSE_TAXES_MODAL:
      return {
        ...state,
        taxesModal: {
          show: false,
        },
      };
    case SHOW_CONFIRM_MODAL:
      return {
        ...state,
        confirmModal: {
          show: true,
          title: action.title,
          description: action.description,
          action: action.action,
          onConfirm: action.onConfirm,
          onCancel: action.onCancel,
        },
      };
    case CLOSE_CONFIRM_MODAL:
      return {
        ...state,
        confirmModal: {
          show: false,
          title: " ",
          description: " ",
          action: { label: "" },
          onConfirm: () => {},
          onCancel: () => {},
        },
      };
    case SHOW_PRODUCT_MODAL:
      return {
        ...state,
        productModal: {
          show: true,
          product: action.product || {},
          activeTab: action.activeTab,
          supplierDisabled: action.supplierDisabled,
          onSubmit: action.onSubmit,
        },
      };
    case CLOSE_PRODUCT_MODAL:
      return {
        ...state,
        productModal: {
          show: false,
          product: {},
          activeTab: null,
          onSubmit: null,
        },
      };
    case SHOW_MOBILE_APP_MODAL:
      return {
        ...state,
        mobileAppModal: {
          show: true,
        },
      };
    case CLOSE_MOBILE_APP_MODAL:
      return {
        ...state,
        mobileAppModal: {
          show: false,
        },
      };
    case SHOW_FILE_MODAL:
      return {
        ...state,
        fileModal: {
          show: true,
          file: action.file || {},
          onSubmit: action.onSubmit,
        },
      };
    case CLOSE_FILE_MODAL:
      return {
        ...state,
        fileModal: {
          show: false,
          file: {},
          baseResourcePath: "",
          onSubmit: null,
        },
      };
    case SHOW_MOVE_FILES_MODAL:
      return {
        ...state,
        moveFilesModal: {
          show: true,
          fileIds: action.fileIds || [],
          onSubmit: action.onSubmit,
        },
      };
    case CLOSE_MOVE_FILES_MODAL:
      return {
        ...state,
        moveFilesModal: {
          show: false,
          fileIds: [],
          baseResourcePath: "",
          onSubmit: null,
        },
      };
    case SHOW_EXPORT_LEDGER_ITEMS_MODAL:
      return {
        ...state,
        exportLedgerItemsModal: {
          show: true,
          onSubmit: action.onSubmit,
        },
      };
    case CLOSE_EXPORT_LEDGER_ITEMS_MODAL:
      return {
        ...state,
        exportLedgerItemsModal: {
          show: false,
          onSubmit: null,
        },
      };
    case SHOW_EXPORT_EXPENSES_MODAL:
      return {
        ...state,
        exportExpensesModal: {
          show: true,
          onSubmit: action.onSubmit,
        },
      };
    case CLOSE_EXPORT_EXPENSES_MODAL:
      return {
        ...state,
        exportExpensesModal: {
          show: false,
          onSubmit: null,
        },
      };
    case SHOW_EXPENSE_CATEGORY_MODAL:
      return {
        ...state,
        expenseCategoryModal: {
          show: true,
          category: action.category || {},
          onSubmit: action.onSubmit,
        },
      };
    case CLOSE_EXPENSE_CATEGORY_MODAL:
      return {
        ...state,
        expenseCategoryModal: {
          show: false,
          category: {},
          onSubmit: null,
        },
      };
    case SHOW_EXPORT_PRODUCTS_MODAL:
      return {
        ...state,
        exportProductsModal: {
          show: true,
          onSubmit: action.onSubmit,
        },
      };
    case CLOSE_EXPORT_PRODUCTS_MODAL:
      return {
        ...state,
        exportProductsModal: {
          show: false,
          onSubmit: null,
        },
      };
    case SHOW_CUSTOM_FIELD_MODAL:
      return {
        ...state,
        customFieldModal: {
          show: true,
          customField: action.customField || { type: CustomFieldType.STRING },
          modelDisabled: action.modelDisabled,
          typeDisabled: action.typeDisabled,
          onSubmit: action.onSubmit,
        },
      };
    case CLOSE_CUSTOM_FIELD_MODAL:
      return {
        ...state,
        customFieldModal: {
          show: false,
          customField: { type: CustomFieldType.STRING },
          modelDisabled: false,
          typeDisabled: false,
          onSubmit: null,
        },
      };
    case SHOW_BOARD_MODAL:
      return {
        ...state,
        boardModal: {
          show: true,
          board: action.board || {},
          contactDisabled: action.contactDisabled,
          projectDisabled: action.projectDisabled,
          onSubmit: action.onSubmit,
        },
      };
    case CLOSE_BOARD_MODAL:
      return {
        ...state,
        boardModal: {
          show: false,
          board: {},
          contactDisabled: false,
          projectDisabled: false,
          onSubmit: null,
        },
      };
    case SHOW_TASK_MODAL:
      return {
        ...state,
        taskModal: {
          show: true,
          task: action.task || {},
          contactDisabled: action.contactDisabled || false,
          projectDisabled: action.projectDisabled || false,
          onSubmit: action.onSubmit,
          onDelete: action.onDelete,
        },
      };
    case CLOSE_TASK_MODAL:
      return {
        ...state,
        taskModal: {
          show: false,
          task: {},
          contactDisabled: false,
          projectDisabled: false,
          onSubmit: null,
          onDelete: null,
        },
      };
    case SHOW_CALENDAR_EVENT_MODAL:
      return {
        ...state,
        calendarEventModal: {
          show: true,
          calendarEvent: action.calendarEvent || {},
          contactDisabled: action.contactDisabled,
          projectDisabled: action.projectDisabled,
          readOnly: action.readOnly,
          onSubmit: action.onSubmit,
          onDelete: action.onDelete,
        },
      };
    case CLOSE_CALENDAR_EVENT_MODAL:
      return {
        ...state,
        calendarEventModal: {
          show: false,
          calendarEvent: {},
          contactDisabled: false,
          projectDisabled: false,
          readOnly: null,
          onSubmit: null,
          onDelete: null,
        },
      };
    case SHOW_ATTACHMENTS_VIEWER_MODAL:
      return {
        ...state,
        attachmentsViewerModal: {
          show: true,
          activeIndex: action.activeIndex,
          attachments: action.attachments,
          actions: action.actions,
        },
      };
    case CLOSE_ATTACHMENTS_VIEWER_MODAL:
      return {
        ...state,
        attachmentsViewerModal: {
          show: false,
          activeIndex: undefined,
          attachments: [],
          actions: null,
          onDelete: null,
        },
      };
    case SHOW_ADD_ACCOUNTANT_MODAL:
      return {
        ...state,
        addAccountantModal: {
          show: true,
          onSubmit: action.onSubmit,
        },
      };
    case CLOSE_ADD_ACCOUNTANT_MODAL:
      return {
        ...state,
        addAccountantModal: {
          show: false,
          onSubmit: null,
        },
      };
    case SHOW_USER_WORKSPACE_SETTING_MODAL:
      return {
        ...state,
        userWorkspaceSettingModal: {
          show: true,
          activeTab: action.activeTab,
          userWorkspaceSetting: action.userWorkspaceSetting,
          onSubmit: action.onSubmit,
        },
      };
    case CLOSE_USER_WORKSPACE_SETTING_MODAL:
      return {
        ...state,
        userWorkspaceSettingModal: {
          show: false,
          activeTab: null,
          userWorkspaceSetting: null,
          onSubmit: null,
        },
      };
    case SHOW_CALCULATE_ADVANCE_MODAL:
      return {
        ...state,
        calculateAmountModal: {
          show: true,
          title: action.title,
          amount: action.amount,
          onSubmit: action.onSubmit,
        },
      };
    case CLOSE_CALCULATE_ADVANCE_MODAL:
      return {
        ...state,
        calculateAmountModal: {
          show: false,
          title: null,
          amount: 0,
          onSubmit: null,
        },
      };
    case SHOW_SEND_CONTRACT_MODAL:
      return {
        ...state,
        sendContractModal: {
          show: true,
          id: action.id,
          onSubmit: action.onSubmit,
        },
      };
    case CLOSE_SEND_CONTRACT_MODAL:
      return {
        ...state,
        sendContractModal: {
          show: false,
          id: null,
          onSubmit: null,
        },
      };
    case SHOW_SHARE_LINKS_MODAL:
      action.shareableLinks;
      return {
        ...state,
        shareLinksModal: {
          show: true,
          title: action.title,
          shareableLinks: action.shareableLinks,
        },
      };
    case CLOSE_SHARE_LINKS_MODAL:
      return {
        ...state,
        shareLinksModal: {
          show: false,
          title: null,
          shareableLinks: [],
        },
      };
    case SHOW_SEND_PROPOSAL_MODAL:
      return {
        ...state,
        sendProposalModal: {
          show: true,
          id: action.id,
          onSubmit: action.onSubmit,
        },
      };
    case CLOSE_SEND_PROPOSAL_MODAL:
      return {
        ...state,
        sendProposalModal: {
          show: false,
          id: null,
          onSubmit: null,
        },
      };
    case SHOW_ITEMS_BLOCK_SETTING_MODAL:
      return {
        ...state,
        itemsBlockSettingModal: {
          show: true,
          itemsBlock: action.block,
          onSubmit: action.onSubmit,
        },
      };
    case CLOSE_ITEMS_BLOCK_SETTING_MODAL:
      return {
        ...state,
        itemsBlockSettingModal: {
          show: false,
          itemsBlock: null,
          onSubmit: null,
        },
      };
    case SHOW_CONTENT_BLOCK_TEMPLATE_MODAL:
      return {
        ...state,
        contentBlockTemplateModal: {
          show: true,
          contentBlockTemplate: action.contentBlockTemplate,
          contentBlockId: action.contentBlockId,
          onSubmit: action.onSubmit,
          onDelete: action.onDelete,
        },
      };
    case CLOSE_CONTENT_BLOCK_TEMPLATE_MODAL:
      return {
        ...state,
        contentBlockTemplateModal: {
          show: false,
          contentBlockTemplate: null,
          contentBlockId: null,
          onSubmit: null,
          onDelete: null,
        },
      };
    case SHOW_ITEM_BLOCK_SETTING_MODAL:
      return {
        ...state,
        itemBlockSettingModal: {
          show: true,
          itemBlock: action.itemBlock,
          onSubmit: action.onSubmit,
        },
      };
    case CLOSE_ITEM_BLOCK_SETTING_MODAL:
      return {
        ...state,
        itemBlockSettingModal: {
          show: false,
          itemBlock: null,
          onSubmit: null,
        },
      };
    case SHOW_DIRECTIONS_MODAL:
      return {
        ...state,
        directionsModal: {
          show: true,
          origin: action.origin ? action.origin.replace("\n", " ") : "",
          destination: action.destination
            ? action.destination.replace("\n", " ")
            : "",
          onSubmit: action.onSubmit,
        },
      };
    case CLOSE_DIRECTIONS_MODAL:
      return {
        ...state,
        directionsModal: {
          show: false,
          origin: null,
          destination: null,
          onSubmit: null,
        },
      };
    case SHOW_LEDGER_ITEM_PAYMENT_MODAL:
      return {
        ...state,
        ledgerItemPaymentModal: {
          show: true,
          ledgerItemPaymentId: action.ledgerItemPaymentId,
          ledgerItemId: action.ledgerItemId,
          onSubmit: action.onSubmit,
        },
      };
    case CLOSE_LEDGER_ITEM_PAYMENT_MODAL:
      return {
        ...state,
        ledgerItemPaymentModal: {
          show: false,
          ledgerItemId: null,
          ledgerItemPaymentId: null,
          onSubmit: null,
        },
      };
    case SHOW_LEDGER_ITEM_TRANSACTION_HISTORY_MODAL:
      return {
        ...state,
        ledgerItemTransactionHistoryModal: {
          show: true,
          ledgerItemId: action.ledgerItemId,
          onSync: action.onSync,
        },
      };
    case CLOSE_LEDGER_ITEM_TRANSACTION_HISTORY_MODAL:
      return {
        ...state,
        ledgerItemTransactionHistoryModal: {
          show: false,
          ledgerItemId: null,
          onSync: null,
        },
      };
    case SHOW_LEDGER_ITEM_CLAIM_MODAL:
      return {
        ...state,
        ledgerItemClaimModal: {
          show: true,
          ledgerItemId: action.ledgerItemId,
          onSubmit: action.onSubmit,
        },
      };
    case CLOSE_LEDGER_ITEM_CLAIM_MODAL:
      return {
        ...state,
        ledgerItemClaimModal: {
          show: false,
          ledgerItemId: null,
          onSubmit: null,
        },
      };
    case SHOW_PAYMENT_MODAL:
      return {
        ...state,
        paymentModal: {
          show: true,
          payment: action.payment,
          onSubmit: action.onSubmit,
        },
      };
    case CLOSE_PAYMENT_MODAL:
      return {
        ...state,
        paymentModal: {
          show: false,
          payment: null,
          onSubmit: null,
        },
      };
    case SHOW_PAYMENT_INITIATION_MODAL:
      return {
        ...state,
        paymentInitiationModal: {
          show: true,
          paymentInitiation: action.paymentInitiation,
          onSubmit: action.onSubmit,
          onDelete: action.onDelete,
        },
      };
    case CLOSE_PAYMENT_INITIATION_MODAL:
      return {
        ...state,
        paymentInitiationModal: {
          show: false,
          paymentInitiation: null,
          onSubmit: null,
          onDelete: null,
        },
      };
    case SHOW_DEAL_MODAL:
      return {
        ...state,
        dealModal: {
          show: true,
          deal: action.deal,
          contactDisabled: action.contactDisabled,
          projectDisabled: action.projectDisabled,
          activeTab: action.activeTab,
          onSubmit: action.onSubmit,
        },
      };
    case CLOSE_DEAL_MODAL:
      return {
        ...state,
        dealModal: {
          show: false,
          deal: null,
          contactDisabled: false,
          projectDisabled: false,
          activeTab: null,
          onSubmit: null,
        },
      };
    case SHOW_DEAL_STAGE_MODAL:
      return {
        ...state,
        dealStageModal: {
          show: true,
          dealStage: action.dealStage,
          onSubmit: action.onSubmit,
        },
      };
    case CLOSE_DEAL_STAGE_MODAL:
      return {
        ...state,
        dealStageModal: {
          show: false,
          dealStage: null,
          onSubmit: null,
        },
      };
    case SHOW_TAX_MODAL:
      return {
        ...state,
        taxModal: {
          show: true,
          tax: action.tax,
          onSubmit: action.onSubmit,
        },
      };
    case CLOSE_TAX_MODAL:
      return {
        ...state,
        taxModal: {
          show: false,
          tax: null,
          onSubmit: null,
        },
      };
    case SHOW_WORK_TYPE_MODAL:
      return {
        ...state,
        workTypeModal: {
          show: true,
          workType: action.workType,
          onSubmit: action.onSubmit,
        },
      };
    case CLOSE_WORK_TYPE_MODAL:
      return {
        ...state,
        workTypeModal: {
          show: false,
          workType: null,
          onSubmit: null,
        },
      };
    case SHOW_DISCOUNT_MODAL:
      return {
        ...state,
        discountModal: {
          show: true,
          currency: action.currency,
          total: action.total,
          discount: action.discount,
          onSubmit: action.onSubmit,
        },
      };
    case CLOSE_DISCOUNT_MODAL:
      return {
        ...state,
        discountModal: {
          show: false,
          currency: null,
          total: null,
          discount: null,
          onSubmit: null,
        },
      };
    case SHOW_PEPPOL_PARTICIPANT_MODAL:
      return {
        ...state,
        peppolParticipantModal: {
          show: true,
          query: action.query,
          onSubmit: action.onSubmit,
        },
      };
    case CLOSE_PEPPOL_PARTICIPANT_MODAL:
      return {
        ...state,
        peppolParticipantModal: {
          show: false,
          query: null,
          onSubmit: null,
        },
      };
    case SHOW_FINANCIAL_YEAR_MODAL:
      return {
        ...state,
        financialYearModal: {
          show: true,
          financialYear: action.financialYear,
          onSubmit: action.onSubmit,
        },
      };
    case CLOSE_FINANCIAL_YEAR_MODAL:
      return {
        ...state,
        financialYearModal: {
          show: false,
          financialYear: null,
          onSubmit: null,
        },
      };
    case SHOW_TRANSACTION_MODAL:
      return {
        ...state,
        transactionModal: {
          show: true,
          transaction: action.transaction,
          activeTab: action.activeTab,
          onSubmit: action.onSubmit,
        },
      };
    case CLOSE_TRANSACTION_MODAL:
      return {
        ...state,
        transactionModal: {
          show: false,
          transaction: null,
          activeTab: null,
          onSubmit: null,
        },
      };
    case SHOW_QUESTION_AND_ANSWER_BLOCK_SETTING_MODAL:
      return {
        ...state,
        questionAndAnswerBlockSettingModal: {
          show: true,
          questionAndAnswerBlock: action.block,
          onSubmit: action.onSubmit,
        },
      };
    case CLOSE_QUESTION_AND_ANSWER_BLOCK_SETTING_MODAL:
      return {
        ...state,
        questionAndAnswerBlockSettingModal: {
          show: false,
          questionAndAnswerBlock: null,
          onSubmit: null,
        },
      };
    case SHOW_PLAYBOOK_SUBMISSION_MODAL:
      return {
        ...state,
        playbookSubmissionModal: {
          show: true,
          playbookSubmission: action.playbookSubmission,
          onSubmit: action.onSubmit,
        },
      };
    case CLOSE_PLAYBOOK_SUBMISSION_MODAL:
      return {
        ...state,
        playbookSubmissionModal: {
          show: false,
          playbookSubmission: null,
          onSubmit: null,
        },
      };
    case SHOW_BULK_PROJECT_STATUS_UPDATE_MODAL:
      return {
        ...state,
        bulkProjectStatusUpdateModal: {
          show: true,
          excludeStatuses: action?.excludeStatuses || [],
          onSubmit: action.onSubmit,
        },
      };
    case CLOSE_BULK_PROJECT_STATUS_UPDATE_MODAL:
      return {
        ...state,
        bulkProjectStatusUpdateModal: {
          show: false,
          excludeStatuses: [],
          onSubmit: null,
        },
      };
    case SHOW_SIGNATURE_MODAL:
      return {
        ...state,
        signatureModal: {
          show: true,
          onSubmit: action.onSubmit,
          onClose: action.onClose,
        },
      };
    case CLOSE_SIGNATURE_MODAL:
      return {
        ...state,
        signatureModal: {
          show: false,
          onSubmit: null,
          onClose: null,
        },
      };
    case SHOW_EMAIL_VIEWER_MODAL:
      return {
        ...state,
        emailViewerModal: {
          show: true,
          email: action.email,
        },
      };
    case CLOSE_EMAIL_VIEWER_MODAL:
      return {
        ...state,
        emailViewerModal: {
          show: false,
          email: null,
        },
      };
    case SHOW_CONTACT_GROUP_MODAL:
      return {
        ...state,
        contactGroupModal: {
          show: true,
          contactGroup: action.contactGroup,
          onSubmit: action.onSubmit,
        },
      };
    case CLOSE_CONTACT_GROUP_MODAL:
      return {
        ...state,
        contactGroupModal: {
          show: false,
          contactGroup: null,
          onSubmit: null,
        },
      };
    case SHOW_BULK_CONTACT_GROUP_MODAL:
      return {
        ...state,
        bulkContactGroupModal: {
          show: true,
          action: action.action,
          contactIds: action.contactIds,
          onSubmit: action.onSubmit,
        },
      };
    case CLOSE_BULK_CONTACT_GROUP_MODAL:
      return {
        ...state,
        bulkContactGroupModal: {
          show: false,
          action: null,
          contactIds: [],
          onSubmit: null,
        },
      };
    case SHOW_EXPORT_CONTACTS_MODAL:
      return {
        ...state,
        exportContactsModal: {
          show: true,
          onSubmit: action.onSubmit,
        },
      };
    case CLOSE_EXPORT_CONTACTS_MODAL:
      return {
        ...state,
        exportContactsModal: {
          show: false,
          onSubmit: null,
        },
      };
    case SHOW_CONTACT_TYPE_MODAL:
      return {
        ...state,
        contactTypeModal: {
          show: true,
          onSubmit: action.onSubmit,
        },
      };
    case CLOSE_CONTACT_TYPE_MODAL:
      return {
        ...state,
        contactTypeModal: {
          show: false,
          onSubmit: null,
        },
      };
    case SHOW_FILE_UPLOAD_MODAL:
      return {
        ...state,
        fileUploadModal: {
          show: true,
          resourceId: action.resourceId,
          resourceType: action.resourceType,
          onSubmit: action.onSubmit,
        },
      };
    case CLOSE_FILE_UPLOAD_MODAL:
      return {
        ...state,
        fileUploadModal: {
          show: false,
          resourceId: null,
          resourceType: null,
          onSubmit: null,
        },
      };
    case SHOW_PAYMENT_QR_MODAL:
      return {
        ...state,
        paymentQRModal: {
          show: true,
          name: action.name,
          iban: action.iban,
          amount: action.amount,
          currency: action.currency,
          remittanceInformation: action.remittanceInformation,
          remittanceInformationType: action.remittanceInformationType,
          onSubmit: action.onSubmit,
        },
      };
    case CLOSE_PAYMENT_QR_MODAL:
      return {
        ...state,
        paymentQRModal: {
          show: false,
          name: null,
          iban: null,
          amount: null,
          currency: null,
          remittanceInformation: null,
          remittanceInformationType: null,
          onSubmit: null,
        },
      };
    case SHOW_CUSTOM_COMPONENT_MODAL:
      return {
        ...state,
        customComponentModal: {
          show: true,
          title: action.title,
          component: action.component,
        },
      };
    case CLOSE_CUSTOM_COMPONENT_MODAL:
      return {
        ...state,
        customComponentModal: {
          show: false,
          title: "",
          component: null,
        },
      };
    case SHOW_DOCUMENT_MODAL:
      return {
        ...state,
        documentModal: {
          show: true,
          document: action.document,
          onSubmit: action.onSubmit,
        },
      };
    case CLOSE_DOCUMENT_MODAL:
      return {
        ...state,
        documentModal: {
          show: false,
          document: null,
          onSubmit: null,
        },
      };
    case SHOW_DOCUMENT_TAG_MODAL:
      return {
        ...state,
        documentTagModal: {
          show: true,
          documentTag: action.documentTag,
          onSubmit: action.onSubmit,
          onDelete: action.onDelete,
        },
      };
    case CLOSE_DOCUMENT_TAG_MODAL:
      return {
        ...state,
        documentTagModal: {
          show: false,
          documentTag: null,
          onSubmit: null,
          onDelete: null,
        },
      };
    case SHOW_EXPORT_DOCUMENTS_MODAL:
      return {
        ...state,
        exportDocumentsModal: {
          show: true,
          onSubmit: action.onSubmit,
        },
      };
    case CLOSE_EXPORT_DOCUMENTS_MODAL:
      return {
        ...state,
        exportDocumentsModal: {
          show: false,
          onSubmit: null,
        },
      };
    case SHOW_UPLOAD_DOCUMENTS_MODAL:
      return {
        ...state,
        uploadDocumentsModal: {
          show: true,
          onSubmit: action.onSubmit,
        },
      };
    case CLOSE_UPLOAD_DOCUMENTS_MODAL:
      return {
        ...state,
        uploadDocumentsModal: {
          show: false,
          onSubmit: null,
        },
      };
    case SHOW_PRODUCT_IMPORT_MODAL:
      return {
        ...state,
        productImportModal: {
          show: true,
        },
      };
    case CLOSE_PRODUCT_IMPORT_MODAL:
      return {
        ...state,
        productImportModal: {
          show: false,
          onSubmit: null,
        },
      };
    case SHOW_CALL_MODAL:
      return {
        ...state,
        callModal: {
          show: true,
          call: action.call,
          onSubmit: action.onSubmit,
        },
      };
    case CLOSE_CALL_MODAL:
      return {
        ...state,
        callModal: {
          show: false,
          call: null,
          onSubmit: null,
        },
      };
    case SHOW_SEND_EMAIL_MODAL:
      return {
        ...state,
        sendEmailModal: {
          show: true,
          email: action.email,
          onSubmit: action.onSubmit,
        },
      };
    case CLOSE_SEND_EMAIL_MODAL:
      return {
        ...state,
        sendEmailModal: {
          show: false,
          email: null,
          onSubmit: null,
        },
      };
    case SHOW_EMAIL_TEMPLATE_MODAL:
      return {
        ...state,
        emailTemplateModal: {
          show: true,
          emailTemplate: action.emailTemplate,
          onSubmit: action.onSubmit,
        },
      };
    case CLOSE_EMAIL_TEMPLATE_MODAL:
      return {
        ...state,
        emailTemplateModal: {
          show: false,
          emailTemplate: null,
          onSubmit: null,
        },
      };
    case SHOW_SEQUENCE_STEP_MODAL:
      return {
        ...state,
        sequenceStepModal: {
          show: true,
          sequenceStep: action.sequenceStep,
          index: action.index,
          onSubmit: action.onSubmit,
        },
      };
    case CLOSE_SEQUENCE_STEP_MODAL:
      return {
        ...state,
        sequenceStepModal: {
          show: false,
          sequenceStep: null,
          index: null,
          onSubmit: null,
        },
      };
    case SHOW_CONTACT_ENROLLMENT_MODAL:
      return {
        ...state,
        contactEnrollmentModal: {
          show: true,
          contactId: action.contactId,
        },
      };
    case CLOSE_CONTACT_ENROLLMENT_MODAL:
      return {
        ...state,
        contactEnrollmentModal: {
          show: false,
          contactId: null,
        },
      };
    case SHOW_TWO_FACTOR_AUTHENTICATION_MODAL:
      return {
        ...state,
        twoFactorAuthenticationModal: {
          show: true,
          onSubmit: action.onSubmit,
        },
      };
    case CLOSE_TWO_FACTOR_AUTHENTICATION_MODAL:
      return {
        ...state,
        twoFactorAuthenticationModal: {
          show: false,
          onSubmit: null,
        },
      };
    default:
      return state;
  }
};
