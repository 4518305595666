import { ConfigState, ConfigActionTypes } from "./types";

const initialState: ConfigState = {
  stripePublicKey: null,
  version: null,
};

export default (
  state = initialState,
  action: ConfigActionTypes
): ConfigState => {
  switch (action.type) {
    default:
      return state;
  }
};
