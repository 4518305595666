import { TimerState, TimerActionTypes, TIMER_START, TIMER_STOP, TIMER_PAUSE, TIMER_RESET } from './types'

const initialState: TimerState = {
	show: false,
	entry: null
}

export default (state = initialState, action: TimerActionTypes): TimerState => {
	switch (action.type) {
		case TIMER_START:
		case TIMER_PAUSE:
			return {
				...state,
				show: true,
				entry: action.entry
			}
		case TIMER_STOP:
		case TIMER_RESET:
			return {
				...state,
				show: false,
				entry: null,
			}
    default:
      return state
  }
}