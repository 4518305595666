import { ChannelsState, ChannelActionTypes, SET_CHANNELS } from './types'

const initialState: ChannelsState = {
	workspaceChannel: null,
}

export default (state = initialState, action: ChannelActionTypes): ChannelsState => {
  switch (action.type) {
    case SET_CHANNELS:
      return {
				...state,
				...action.channelState
      }
    default:
      return state
  }
}