import { Board, BoardLabel, BoardList, Task } from "../../types"

export const SET_INITIAL_BOARD_STATE = "SET_INITIAL_BOARD_STATE"
export const SET_BOARD = "SET_BOARD"
export const BOARD_CREATE = "BOARD_CREATE"
export const BOARD_UPDATE = "BOARD_UPDATE"
export const BOARD_DELETE = "BOARD_DELETE"
export const BOARD_LABEL_CREATE = "BOARD_LABEL_CREATE"
export const BOARD_LABEL_UPDATE = "BOARD_LABEL_UPDATE"
export const BOARD_LABEL_DELETE = "BOARD_LABEL_DELETE"
export const BOARD_LIST_CREATE = "BOARD_LIST_CREATE"
export const BOARD_LIST_UPDATE = "BOARD_LIST_UPDATE"
export const BOARD_LIST_DELETE = "BOARD_LIST_DELETE"
export const TASK_CREATE = "TASK_CREATE"
export const TASK_UPDATE = "TASK_UPDATE"
export const TASK_DELETE = "TASK_DELETE"

export interface BoardState {
	board: Board | null,
	lists: BoardList[]
	labels: BoardLabel[]
	tasks: Task[]
}

interface SetInitialStateAction {
	type: typeof SET_INITIAL_BOARD_STATE,
	state: BoardState
}

interface SetBoardAction {
	type: typeof SET_BOARD,
	board: Board
}

interface BoardCreateAction {
	type: typeof BOARD_CREATE,
	board: Board
}

interface BoardUpdateAction {
	type: typeof BOARD_UPDATE,
	board: Board
}

interface BoardDeleteAction {
	type: typeof BOARD_DELETE,
	id: string
}

interface BoardLabelCreateAction {
	type: typeof BOARD_LABEL_CREATE,
	boardLabel: Board
}

interface BoardLabelUpdateAction {
	type: typeof BOARD_LABEL_UPDATE,
	boardLabel: Board
}

interface BoardLabelDeleteAction {
	type: typeof BOARD_LABEL_DELETE,
	id: string
}

interface BoardListCreateAction {
	type: typeof BOARD_LIST_CREATE,
	boardList: BoardList
}

interface BoardListUpdateAction {
	type: typeof BOARD_LIST_UPDATE,
	boardList: BoardList
}

interface BoardListDeleteAction {
	type: typeof BOARD_LIST_DELETE,
	id: string
}

interface TaskCreateAction {
	type: typeof TASK_CREATE,
	task: Task
}

interface TaskUpdateAction {
	type: typeof TASK_UPDATE,
	task: Task
}

interface TaskDeleteAction {
	type: typeof TASK_DELETE,
	id: string
}

export type BoardActionTypes = (
	SetInitialStateAction |
	SetBoardAction |
	BoardCreateAction |
	BoardUpdateAction |
	BoardDeleteAction |
	BoardLabelCreateAction |
	BoardLabelUpdateAction |
	BoardLabelDeleteAction |
	BoardListCreateAction |
	BoardListUpdateAction |
	BoardListDeleteAction |
	TaskCreateAction |
	TaskUpdateAction |
	TaskDeleteAction
)