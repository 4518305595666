export default class ChatWidgetHelper {
  static show() {
    // @ts-ignore
    window?.drift?.api?.widget?.show();
  }

  static hide() {
    // @ts-ignore
    window?.drift?.api?.widget?.hide();
  }

  static openChat() {
    // @ts-ignore
    window?.drift?.api?.openChat();
  }

  static setLocale(locale) {
    // @ts-ignore
    window?.drift?.config({ locale: locale })
  }

  static onReady(callback: (api: any) => void) {
    // @ts-ignore
    window?.drift?.on('ready', callback)
  }
}