import BoardHelper from '../../helpers/BoardHelper'
import Utils from '../../utilities/Utils'
import {
	BoardState, BoardActionTypes,
	SET_INITIAL_BOARD_STATE,
	SET_BOARD,
	BOARD_CREATE,
	BOARD_UPDATE,
	BOARD_DELETE,
	BOARD_LABEL_CREATE,
	BOARD_LABEL_UPDATE,
	BOARD_LABEL_DELETE,
	BOARD_LIST_CREATE,
	BOARD_LIST_UPDATE,
	BOARD_LIST_DELETE,
	TASK_CREATE,
	TASK_UPDATE,
	TASK_DELETE
} from './types'

const initialState: BoardState = {
	board: null,
	labels: [],
	lists: [],
	tasks: []
}

export default (state = initialState, action: BoardActionTypes): BoardState => {
	switch (action.type) {
		case SET_INITIAL_BOARD_STATE:
			return {
				board: action.state.board,
				labels: action.state.labels,
				lists: Utils.sortByPosition(action.state.lists),
				tasks: Utils.sortByPosition(action.state.tasks),
			}
		case SET_BOARD:
			return { ...state, board: action.board }
		case BOARD_CREATE: // do nothing at this point in time
			break
		case BOARD_UPDATE:
			return { ...state, board: action.board }
		case BOARD_DELETE:
			return { ...state, board: null }
		case BOARD_LABEL_CREATE:
			return { ...state, labels: [...state.labels, action.boardLabel] }
		case BOARD_LABEL_UPDATE:
			// Find index
			const labelUpdateIndex = state.labels.findIndex(label => label.id === action.boardLabel.id)

			// Update label at index
			state.labels[labelUpdateIndex] = action.boardLabel

			// Set new state
			return { ...state, labels: [...state.labels] }
		case BOARD_LABEL_DELETE:
			return { ...state, labels: [...state.labels.filter(label => label.id !== action.id)] }
		case BOARD_LIST_CREATE:
			const existingListIndex = state.lists.findIndex(list => list.id === action.boardList.id)

			if (existingListIndex !== -1) {
				// Update task at index
				state.lists[existingListIndex] = action.boardList

				return { ...state, lists: Utils.sortByPosition([...state.lists]) }
			} else {
				return { ...state, lists: Utils.sortByPosition([...state.lists, action.boardList]) }
			}

		case BOARD_LIST_UPDATE:
			// Find index
			const listUpdateIndex = state.lists.findIndex(list => list.id === action.boardList.id)

			// Update at index
			state.lists[listUpdateIndex] = action.boardList

			// Set new state
			return { ...state, lists: Utils.sortByPosition([...state.lists]) }
		case BOARD_LIST_DELETE:
			return { ...state, lists: [...state.lists.filter(list => list.id !== action.id)] }
		case TASK_CREATE:
			const existingTaskIndex = state.tasks.findIndex(task => task.id === action.task.id)

			if (existingTaskIndex !== -1) {
				// Update task at index
				state.tasks[existingTaskIndex] = action.task

				return { ...state, tasks: Utils.sortByPosition([...state.tasks]) }
			} else {
				return { ...state, tasks: Utils.sortByPosition([...state.tasks, action.task]) }
			}
		case TASK_UPDATE:
			// Find index
			const taskUpdateIndex = state.tasks.findIndex(task => task.id === action.task.id)

			// Update at index
			state.tasks[taskUpdateIndex] = action.task

			// Set new state
			return { ...state, tasks: Utils.sortByPosition([...state.tasks]) }
		case TASK_DELETE:
			return { ...state, tasks: [...state.tasks.filter(task => task.id !== action.id)] }
		default:
			return state
	}
}