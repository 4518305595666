import { SequenceMetadata, SequenceStep } from "../../types";

export const SET_INITIAL_SEQUENCE_STEP_EDITOR_STATE =
  "SET_INITIAL_SEQUENCE_STEPS_STATE";
export const SEQUENCE_STEP_CREATE = "SEQUENCE_STEP_CREATE";
export const SEQUENCE_STEP_UPDATE = "SEQUENCE_STEP_UPDATE";
export const SEQUENCE_STEP_DELETE = "SEQUENCE_STEP_DELETE";

export interface SequenceStepEditorState {
  steps: SequenceStep[] | null;
}

interface SetInitialStateAction {
  type: typeof SET_INITIAL_SEQUENCE_STEP_EDITOR_STATE;
  state: SequenceStepEditorState;
}

interface SequenceStepCreateAction {
  type: typeof SEQUENCE_STEP_CREATE;
  sequenceStep: SequenceStep;
}

interface SequenceStepUpdateAction {
  type: typeof SEQUENCE_STEP_UPDATE;
  sequenceStep: SequenceStep;
}

interface SequenceStepDeleteAction {
  type: typeof SEQUENCE_STEP_DELETE;
  id: string;
}

export type SequenceStepsActionTypes =
  | SetInitialStateAction
  | SequenceStepCreateAction
  | SequenceStepUpdateAction
  | SequenceStepDeleteAction;
