export const SHOW_NAVIGATION = "SHOW_NAVIGATION"
export const HIDE_NAVIGATION = "HIDE_NAVIGATION"
export const TOGGLE_NAVIGATION = "TOGGLE_NAVIGATION"

export interface NavigationState {
  active: boolean
}

export interface ShowNavigationAction {
  type: typeof SHOW_NAVIGATION;
}

export interface HideNavigationAction {
  type: typeof HIDE_NAVIGATION;
}

export interface ToggleNavigationAction {
  type: typeof TOGGLE_NAVIGATION;
}

export type NavigationActionTypes =
  | ShowNavigationAction
  | HideNavigationAction
  | ToggleNavigationAction
