import Utils from "../../utilities/Utils";
import {
  SequenceStepEditorState,
  SET_INITIAL_SEQUENCE_STEP_EDITOR_STATE,
  SEQUENCE_STEP_CREATE,
  SEQUENCE_STEP_UPDATE,
  SEQUENCE_STEP_DELETE,
  SequenceStepsActionTypes,
} from "./types";

const initialState: SequenceStepEditorState = {
  steps: null,
};

export default (
  state = initialState,
  action: SequenceStepsActionTypes
): SequenceStepEditorState => {
  switch (action.type) {
    case SET_INITIAL_SEQUENCE_STEP_EDITOR_STATE:
      return {
        steps: Utils.sortByPosition(action.state.steps),
      };
    case SEQUENCE_STEP_CREATE:
      return {
        ...state,
        steps: Utils.sortByPosition([...state.steps, action.sequenceStep]),
      };
    case SEQUENCE_STEP_UPDATE:
      // Find index
      const sequenceStepUpdateIndex = state.steps.findIndex(
        (sequenceStep) => sequenceStep.id === action.sequenceStep.id
      );

      // Update label at index
      state.steps[sequenceStepUpdateIndex] = action.sequenceStep;

      // Set new state
      return {
        ...state,
        steps: Utils.sortByPosition([...state.steps]),
      };
    case SEQUENCE_STEP_DELETE:
      return {
        ...state,
        steps: Utils.sortByPosition([
          ...state.steps.filter(
            (sequenceStep) => sequenceStep.id !== action.id
          ),
        ]),
      };
    default:
      return state;
  }
};
