import { TimeEntry } from "../../types"

export const TIMER_START = 'TIMER_START'
export const TIMER_PAUSE = 'TIMER_PAUSE'
export const TIMER_STOP = 'TIMER_STOP'
export const TIMER_RESET = 'TIMER_RESET'

export interface TimerState {
	show: boolean
	entry: TimeEntry | null
}

interface TimerStartAction {
	type: typeof TIMER_START,
	entry: TimeEntry
}

interface TimerPauseAction {
	type: typeof TIMER_PAUSE,
	entry: TimeEntry
}

interface TimerStopAction {
	type: typeof TIMER_STOP,
}

interface TimerResetAction {
	type: typeof TIMER_RESET,
}

export type TimerActionTypes = (
	TimerStartAction |
	TimerPauseAction |
	TimerStopAction |
	TimerResetAction
)