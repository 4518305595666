import { CurrentUser, Settings, Subscription, Workspace } from "../../types";
import {
  AuthenticationState,
  AuthenticationActionTypes,
  SET_TOKEN,
  UNSET_TOKEN,
  UPDATE_WORKSPACE,
  UPDATE_SETTINGS,
  UPDATE_SUBSCRIPTION,
  SET_CURRENT_USER,
} from "./types";

const initialState: AuthenticationState = {
  currentUser: null,
  accessToken: null,
  currentAccountant: null,
};

export default (
  state = initialState,
  action: AuthenticationActionTypes
): AuthenticationState => {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.currentUser,
      };
    case SET_TOKEN:
      return {
        ...state,
        accessToken: action.accessToken,
      };
    case UNSET_TOKEN:
      return {
        ...state,
        accessToken: null,
      };
    case UPDATE_WORKSPACE:
      const updatedCurrentUserWorkspace: CurrentUser = Object.assign(
        {},
        state.currentUser,
        { workspace: action.workspace }
      );
      return {
        ...state,
        currentUser: updatedCurrentUserWorkspace,
      };
    case UPDATE_SETTINGS:
      const updatedSettings: Settings = {
        ...state.currentUser.workspace.setting,
        ...action.settings,
      };
      const updatedWorkspace: Workspace = {
        ...state.currentUser.workspace,
        setting: action.settings,
      };

      const updateCurrentUserWithSettings: CurrentUser = {
        ...state.currentUser,
        workspace: updatedWorkspace,
      };

      return {
        ...state,
        currentUser: updateCurrentUserWithSettings,
      };
    case UPDATE_SUBSCRIPTION:
      const updatedSubscription: Subscription = {
        ...state.currentUser.workspace.subscription,
        ...action.subscription,
      };

      const updatedSubscriptionWorkspace: Workspace = {
        ...state.currentUser.workspace,
        subscription: updatedSubscription,
      };

      const updatedCurrentUser: CurrentUser = {
        ...state.currentUser,
        workspace: updatedSubscriptionWorkspace,
      };

      return {
        ...state,
        currentUser: updatedCurrentUser,
      };
    default:
      return state;
  }
};
