import { Locale } from "./types"

export const SENTRY_DSN = 'https://4a057bdbc6914eecae469d1569917844@o150560.ingest.sentry.io/1478543'

// @ts-ignore
export const STRIPE_PUBLIC_KEY = window.__STATE__.config.stripePublicKey
export const GOOGLE_MAPS_KEY = 'AIzaSyCd_gom9kDWg0k8M_OW1CEP8fdoOHJiIs8'
export const FROALA_KEY = '0BA3jA9D7C4C4B4D4C3bHIMFF1EWBXIJb1BZLZFh1i1MXQLjE4C3F3F3B4A5C4E3C3A1=='

export const RAILS_DIRECT_UPLOAD_URL = '/rails/active_storage/direct_uploads'
export const RAILS_PUBLIC_DIRECT_UPLOAD_URL = '/rails/active_storage/public_direct_uploads'
export const HELPCENTER_URL = 'https://bizzey.crunch.help/'

// i18n.t('OrderFormValidityDurations::0')
// i18n.t('OrderFormValidityDurations::7')
// i18n.t('OrderFormValidityDurations::15')
// i18n.t('OrderFormValidityDurations::30')
// i18n.t('OrderFormValidityDurations::45')
// i18n.t('OrderFormValidityDurations::60')
// i18n.t('OrderFormValidityDurations::70')
export const ORDER_FORM_VALIDITY_DURATIONS = [0, 7, 15, 30, 45, 60, 70]

// i18n.t('QuotationValidityDurations::0')
// i18n.t('QuotationValidityDurations::7')
// i18n.t('QuotationValidityDurations::15')
// i18n.t('QuotationValidityDurations::30')
// i18n.t('QuotationValidityDurations::45')
// i18n.t('QuotationValidityDurations::60')
// i18n.t('QuotationValidityDurations::70')
export const QUOTATION_VALIDITY_DURATIONS = [0, 7, 15, 30, 45, 60, 70]

// i18n.t('InvoicePaymentDurations::0')
// i18n.t('InvoicePaymentDurations::7')
// i18n.t('InvoicePaymentDurations::15')
// i18n.t('InvoicePaymentDurations::30')
// i18n.t('InvoicePaymentDurations::45')
// i18n.t('InvoicePaymentDurations::60')
// i18n.t('InvoicePaymentDurations::70')
export const INVOICE_PAYMENT_DURATIONS = [0, 7, 15, 30, 45, 60, 70]

// i18n.t('SmtpAuthType::login')
// i18n.t('SmtpAuthType::plain')
// i18n.t('SmtpAuthType::cram-md5')

// i18n.t('SmtpSSLType::ssl')
// i18n.t('SmtpSSLType::tls')

export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyCxbXCeiQzxx5ZCHQGHTdoEWfppfGeWxR4",
  authDomain: "bizzey.firebaseapp.com",
  databaseURL: "https://bizzey.firebaseio.com",
  projectId: "bizzey",
  storageBucket: "bizzey.appspot.com",
  messagingSenderId: "1032007128781",
  appId: "1:1032007128781:web:139f6b1ba5880ecd7a7b84"
};

export const FIREBASE_MESSAGING_CONFIG: {
  vapidKey?: string;
  serviceWorkerRegistration?: ServiceWorkerRegistration;
} = {
  vapidKey: 'BDimW4-d3fLrPS7zROCOqhJg8pJFGxQpHMLXFYsBGfce87x3zkQV8ekTjaQ3Or453vWfTDufTHqPQN9QgH_Dfds'
}

export const DEFAULT_LOCALE: Locale = Locale.EN;
export const DEFAULT_CURRENCY = 'EUR'

export const LOCALES: Locale[] = [
  // t('Locales::en')
  Locale.EN,
  // t('Locales::nl')
  Locale.NL,
  // t('Locales::fr')
  Locale.FR,
  // t('Locales::de')
  Locale.DE
]

export const MAX_FILE_SIZE = 20000000
export const MAX_EMAIL_ATTACHMENT_SIZE = 10000000
export const MAX_EMAIL_ATTACHMENTS_SIZE = MAX_FILE_SIZE // 20MB


// DO NOT REMOVE (needed for translations)
// Variable translations used inside of the text editor
// t('EditorVariables::contract::name')
// t('EditorVariables::contract::status')
// t('EditorVariables::contract::EntityName')
// t('EditorVariables::signer::name')
// t('EditorVariables::signer::first_name')
// t('EditorVariables::signer::last_name')
// t('EditorVariables::signer::email')
// t('EditorVariables::signer::link')
// t('EditorVariables::signer::EntityName')
// t('EditorVariables::contact::name')
// t('EditorVariables::contact::alias')
// t('EditorVariables::contact::currency')
// t('EditorVariables::contact::vat_number')
// t('EditorVariables::contact::address')
// t('EditorVariables::contact::pincode')
// t('EditorVariables::contact::hourly_rate')
// t('EditorVariables::contact::day_rate')
// t('EditorVariables::contact::first_name')
// t('EditorVariables::contact::last_name')
// t('EditorVariables::contact::job_title')
// t('EditorVariables::contact::EntityName')
// t('EditorVariables::project::EntityName')
// t('EditorVariables::project::name')
// t('EditorVariables::project::po_number')
// t('EditorVariables::project::estimated_start_date')
// t('EditorVariables::project::estimated_end_date')
// t('EditorVariables::project::start_date')
// t('EditorVariables::project::end_date')
// t('EditorVariables::project::hourly_rate')
// t('EditorVariables::project::day_rate')
// t('EditorVariables::project::budget')
// t('EditorVariables::workspace::business_name')
// t('EditorVariables::workspace::email')
// t('EditorVariables::workspace::street')
// t('EditorVariables::workspace::city')
// t('EditorVariables::workspace::province')
// t('EditorVariables::workspace::zip_code')
// t('EditorVariables::workspace::vat_number')
// t('EditorVariables::workspace::phone_number')
// t('EditorVariables::workspace::account_number')
// t('EditorVariables::workspace::website')
// t('EditorVariables::workspace::EntityName')
// t('EditorVariables::proposal::name')
// t('EditorVariables::proposal::status')
// t('EditorVariables::proposal::EntityName')
// t('EditorVariables::ledger_item::EntityName')
// t('EditorVariables::ledger_item::amount')
// t('EditorVariables::ledger_item::business_name')
// t('EditorVariables::ledger_item::contact_alias')
// t('EditorVariables::ledger_item::contact_name')
// t('EditorVariables::ledger_item::due_date')
// t('EditorVariables::ledger_item::estimated_net_on')
// t('EditorVariables::ledger_item::identifier')
// t('EditorVariables::ledger_item::issue_date')
// t('EditorVariables::ledger_item::paid_on')
// t('EditorVariables::ledger_item::link')
// t('EditorVariables::ledger_item::net_total')
// t('EditorVariables::ledger_item::number')
// t('EditorVariables::ledger_item::link_preview')
// t('EditorVariables::ledger_item::paid_date')
// t('EditorVariables::time_entry::EntityName')
// t('EditorVariables::time_entry::date')
// t('EditorVariables::time_entry::started_on')
// t('EditorVariables::time_entry::ended_on')
// t('EditorVariables::time_entry::description')
// t('EditorVariables::time_entry::duration')
// t('EditorVariables::time_entry::travel_distance')
// t('EditorVariables::work_type::EntityName')
// t('EditorVariables::work_type::name')
// t('EditorVariables::work_type::hourly_rate')
// t('EditorVariables::work_type::day_rate')
// t('EditorVariables::sender::EntityName')
// t('EditorVariables::sender::name')
// t('EditorVariables::sender::email')
