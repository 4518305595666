import { ContentBlock } from "../../types"

export const SET_INITIAL_CONTENT_BLOCK_EDITOR_STATE = "SET_INITIAL_CONTENT_BLOCKS_STATE"
export const CONTENT_BLOCK_CREATE = "CONTENT_BLOCK_CREATE"
export const CONTENT_BLOCK_UPDATE = "CONTENT_BLOCK_UPDATE"
export const CONTENT_BLOCK_DELETE = "CONTENT_BLOCK_DELETE"

export interface ContentBlockEditorState {
	contentBlocks: ContentBlock[] | null,
}

interface SetInitialStateAction {
	type: typeof SET_INITIAL_CONTENT_BLOCK_EDITOR_STATE,
	state: ContentBlockEditorState
}

interface ContentBlockCreateAction {
	type: typeof CONTENT_BLOCK_CREATE,
	contentBlock: ContentBlock
}

interface ContentBlockUpdateAction {
	type: typeof CONTENT_BLOCK_UPDATE,
	contentBlock: ContentBlock
}

interface ContentBlockDeleteAction {
	type: typeof CONTENT_BLOCK_DELETE,
	id: string
}

export type ContentBlocksActionTypes = (
	SetInitialStateAction |
	ContentBlockCreateAction |
	ContentBlockUpdateAction |
	ContentBlockDeleteAction
)