import {
  SHOW_NAVIGATION,
  HIDE_NAVIGATION,
  NavigationActionTypes,
  NavigationState,
  TOGGLE_NAVIGATION
} from './types'

const initialState: NavigationState = {
  active: false
};

export default (
  state = initialState,
  action: NavigationActionTypes
): NavigationState => {
  switch (action.type) {
    case SHOW_NAVIGATION:
      return {
        ...state,
        active: true
      };
    case HIDE_NAVIGATION:
      return {
        ...state,
        active: false
      };
    case TOGGLE_NAVIGATION:
      return {
        ...state,
        active: !state.active
      };
    default:
      return state;
  }
};
