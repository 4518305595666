import { Deal, DealStage } from "../../types";

export const SET_INITIAL_DEALS_BOARD_STATE = "SET_INITIAL_DEALS_BOARD_STATE";
export const DEAL_CREATE = "DEAL_CREATE";
export const DEAL_UPDATE = "DEAL_UPDATE";
export const DEAL_DELETE = "DEAL_DELETE";
export const DEAL_STAGE_CREATE = "DEAL_STAGE_CREATE";
export const DEAL_STAGE_UPDATE = "DEAL_STAGE_UPDATE";
export const DEAL_STAGE_DELETE = "DEAL_STAGE_DELETE";

export interface DealsBoardState {
  deals: Deal[];
  dealStages: DealStage[];
}

interface SetInitialStateAction {
  type: typeof SET_INITIAL_DEALS_BOARD_STATE;
  state: DealsBoardState;
}

interface DealCreateAction {
  type: typeof DEAL_CREATE;
  deal: Deal;
}

interface DealUpdateAction {
  type: typeof DEAL_UPDATE;
  deal: Deal;
}

interface DealDeleteAction {
  type: typeof DEAL_DELETE;
  id: string;
}

interface DealStageCreateAction {
  type: typeof DEAL_STAGE_CREATE;
  dealStage: DealStage;
}

interface DealStageUpdateAction {
  type: typeof DEAL_STAGE_UPDATE;
  dealStage: DealStage;
}

interface DealStageDeleteAction {
  type: typeof DEAL_STAGE_DELETE;
  id: string;
}

export type DealActionTypes =
  | SetInitialStateAction
  | DealCreateAction
  | DealUpdateAction
  | DealDeleteAction
  | DealStageCreateAction
  | DealStageUpdateAction
  | DealStageDeleteAction;
