import Utils from '../../utilities/Utils'
import {
	ContentBlockEditorState,
	SET_INITIAL_CONTENT_BLOCK_EDITOR_STATE,
	CONTENT_BLOCK_CREATE,
	CONTENT_BLOCK_UPDATE,
	CONTENT_BLOCK_DELETE,
	ContentBlocksActionTypes
} from './types'

const initialState: ContentBlockEditorState = {
	contentBlocks: null,
}

export default (state = initialState, action: ContentBlocksActionTypes): ContentBlockEditorState => {
	switch (action.type) {
		case SET_INITIAL_CONTENT_BLOCK_EDITOR_STATE:
			return { contentBlocks: Utils.sortByPosition(action.state.contentBlocks) }
		case CONTENT_BLOCK_CREATE:
			return { ...state, contentBlocks: Utils.sortByPosition([...state.contentBlocks, action.contentBlock]) }
		case CONTENT_BLOCK_UPDATE:
			// Find index
			const contentBlockUpdateIndex = state.contentBlocks.findIndex(contentBlock => contentBlock.id === action.contentBlock.id)

			// Update label at index
			state.contentBlocks[contentBlockUpdateIndex] = action.contentBlock

			// Set new state
			return {
				...state,
				contentBlocks: Utils.sortByPosition([...state.contentBlocks])
			}
		case CONTENT_BLOCK_DELETE:
			return {
				...state,
				contentBlocks: Utils.sortByPosition([...state.contentBlocks.filter(contentBlock => contentBlock.id !== action.id)])
			}
		default:
			return state
	}
}