import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import { DEFAULT_LOCALE } from '../Constants';
import store from '../store';
import ChatWidgetHelper from '../helpers/ChatWidgetHelper';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: DEFAULT_LOCALE,
    lng: DEFAULT_LOCALE,
    keySeparator: '::',
    nsSeparator: false,
    interpolation: {
      escapeValue: true,
      defaultVariables: {
        __appName: 'Bizzey',
      },
    },
    backend: {
      loadPath: '/app/locales/{{lng}}.json',
      queryStringParams: { v: store.getState().config.version },
    },
  });

export const setLocale = (locale: string) => {
  // Set i18n language
  i18n.changeLanguage(locale)

  // Set ChatWidget language
  ChatWidgetHelper.setLocale(locale)
}

export default i18n;