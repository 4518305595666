import {
  createStore,
  applyMiddleware,
  combineReducers,
  Middleware,
} from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";

// Reducers
import AuthenticationReducer from "./authentication/reducer";
import TimerReducer from "./timer/reducer";
import ModalReducer from "./modals/reducer";
import ConfigReducer from "./config/reducer";
import ChannelsReducer from "./channels/reducer";
import BoardReducer from "./board/reducer";
import ContentBlockReducer from "./content-blocks/reducer";
import NavigationReducer from "./navigation/reducer";
import DealsReducer from "./deals/reducer";
import SequenceStepReducer from "./sequence-steps/reducer";

const rootReducer = combineReducers({
  authentication: AuthenticationReducer,
  navigation: NavigationReducer,
  timer: TimerReducer,
  modals: ModalReducer,
  config: ConfigReducer,
  channels: ChannelsReducer,
  board: BoardReducer,
  contentBlockEditor: ContentBlockReducer,
  dealsBoard: DealsReducer,
  sequenceStepEditor: SequenceStepReducer,
});

let middleware: Middleware[] = [thunk];

if (process.env.NODE_ENV !== "production") {
  middleware = [...middleware, logger];
}

export type AppState = ReturnType<typeof rootReducer>;
export default createStore(
  rootReducer,
  window["__STATE__"],
  applyMiddleware(...middleware)
);
