import Utils from "../../utilities/Utils";
import {
  DealsBoardState,
  DealActionTypes,
  SET_INITIAL_DEALS_BOARD_STATE,
  DEAL_CREATE,
  DEAL_UPDATE,
  DEAL_DELETE,
  DEAL_STAGE_CREATE,
  DEAL_STAGE_UPDATE,
  DEAL_STAGE_DELETE,
} from "./types";

const initialState: DealsBoardState = {
  deals: [],
  dealStages: [],
};

export default (
  state = initialState,
  action: DealActionTypes
): DealsBoardState => {
  switch (action.type) {
    case SET_INITIAL_DEALS_BOARD_STATE:
      return {
        deals: Utils.sortByPosition(action.state.deals),
        dealStages: Utils.sortByPosition(action.state.dealStages),
      };
    case DEAL_CREATE:
      return { ...state, deals: [...state.deals, action.deal] };
    case DEAL_UPDATE:
      // Find index
      const labelUpdateIndex = state.deals.findIndex(
        (label) => label.id === action.deal.id
      );

      // Update label at index
      state.deals[labelUpdateIndex] = action.deal;

      // Set new state
      return { ...state, deals: [...state.deals] };
    case DEAL_DELETE:
      return {
        ...state,
        deals: [...state.deals.filter((label) => label.id !== action.id)],
      };
    case DEAL_STAGE_CREATE:
      const existingListIndex = state.deals.findIndex(
        (list) => list.id === action.dealStage.id
      );

      if (existingListIndex !== -1) {
        // Update task at index
        state.dealStages[existingListIndex] = action.dealStage;

        return {
          ...state,
          dealStages: Utils.sortByPosition([...state.dealStages]),
        };
      } else {
        return {
          ...state,
          dealStages: Utils.sortByPosition([
            ...state.dealStages,
            action.dealStage,
          ]),
        };
      }

    case DEAL_STAGE_UPDATE:
      // Find index
      const listUpdateIndex = state.dealStages.findIndex(
        (list) => list.id === action.dealStage.id
      );

      // Update at index
      state.dealStages[listUpdateIndex] = action.dealStage;

      // Set new state
      return {
        ...state,
        dealStages: Utils.sortByPosition([...state.dealStages]),
      };
    case DEAL_STAGE_DELETE:
      return {
        ...state,
        dealStages: [
          ...state.dealStages.filter((list) => list.id !== action.id),
        ],
      };
    default:
      return state;
  }
};
